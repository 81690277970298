import React
from 'react';

import {Segment, Container, Header, Popup, Table }
from 'semantic-ui-react';

export function SchoolsPage(props){
  function chgSort(cCol){
    if (props.sortS.sCol !== cCol) {
      props.setSortS({sCol: cCol, dir: 1});
    }
    else{
      props.setSortS({sCol: cCol, dir: (props.sortS.dir === 1 ? -1 : 1)});
    }
  }
  return (
    <Segment>
      <Container fluid style={{width: "100%", paddingTop: 80, paddingBottom: 20}}>
        <Header size="medium">
          Übersicht Schulen NRW
        </Header>
      </Container>
      <div style={{overflow:'auto', maxHeight:'600px', borderStyle:'solid', borderWidth:'thin', borderColor:'#EEE'}}>
        <Table singleLine striped compact fixed sortable>
          <Table.Header>
            <Table.Row>
              <Popup content='Schulnummer' trigger={
                <Table.HeaderCell style  ={{position:'sticky',borderStyle:'hidden',top:0,zIndex:'10',background:'#EEE'}}
                                  width  ={2}
                                  sorted ={props.sortS.sCol === 'no'?(props.sortS.dir === 1?'ascending':'descending'):null}
                                  onClick={() => chgSort('no')}>
                  SchulNr
                </Table.HeaderCell>
              }/>
              <Popup content='Name' trigger={
                <Table.HeaderCell style  ={{position:'sticky',borderStyle:'hidden',top:0,zIndex:'10',background:'#EEE'}}
                                  width  ={5}
                                  sorted ={props.sortS.sCol === 'name'?(props.sortS.dir === 1?'ascending':'descending'):null}
                                  onClick={() => chgSort('name')}>
                  Name
                </Table.HeaderCell>
              }/>
              <Popup content='Stadt' trigger={
                <Table.HeaderCell style  ={{position:'sticky',borderStyle:'hidden',top:0,zIndex:'10',background:'#EEE'}}
                                  width  ={5}
                                  sorted ={props.sortS.sCol === 'city'?(props.sortS.dir === 1?'ascending':'descending'):null}
                                  onClick={() => chgSort('city')}>
                  Stadt
                </Table.HeaderCell>
              }/>
              <Popup content='Strasse' trigger={
                <Table.HeaderCell style  ={{position:'sticky',borderStyle:'hidden',top:0,zIndex:'10',background:'#EEE'}}
                                  width  ={5}
                                  sorted ={props.sortS.sCol === 'street'?(props.sortS.dir === 1?'ascending':'descending'):null}
                                  onClick={() => chgSort('street')}>
                  Strasse
                </Table.HeaderCell>
              }/>
              <Popup content='Schulmail' trigger={
                <Table.HeaderCell style  ={{position:'sticky',borderStyle:'hidden',top:0,zIndex:'10',background:'#EEE'}}
                                  width  ={4}
                                  sorted ={props.sortS.sCol === 'mail'?(props.sortS.dir === 1?'ascending':'descending'):null}
                                  onClick={() => chgSort('mail')}>
                  Schulmail
                </Table.HeaderCell>
              }/>
              <Popup content='Status' trigger={
                <Table.HeaderCell style  ={{position:'sticky',borderStyle:'hidden',top:0,zIndex:'10',background:'#EEE'}}
                                  width  ={1}
                                  sorted ={props.sortS.sCol === 'aState'?(props.sortS.dir === 1?'ascending':'descending'):null}
                                  onClick={() => chgSort('aState')}>
                  Status
                </Table.HeaderCell>
              }/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {
            props.Schools.map((school, i) => (
              <Table.Row key={i}>
                <Table.Cell>{school.no     }</Table.Cell>
                <Table.Cell>{school.name   }</Table.Cell>
                <Table.Cell>{school.city   }</Table.Cell>
                <Table.Cell>{school.street }</Table.Cell>
                <Table.Cell>{school.mail   }</Table.Cell>
                <Table.Cell>{school.aState }</Table.Cell>
              </Table.Row>
            ))
          }
          </Table.Body>
        </Table>
      </div>
    </Segment>
  );
}
