/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSchool = /* GraphQL */ `
  query GetSchool($no: String!) {
    getSchool(no: $no) {
      id
      no
      name
      plz
      city
      street
      mail
      vorname
      nachname
      aState
      bDate
      dDate
      vornameJ
      nachnameJ
      aStateJ
      bDateJ
      dDateJ
      aIP
      hsURL
      createdAt
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $no: String
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchools(
      no: $no
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        no
        name
        plz
        city
        street
        mail
        vorname
        nachname
        aState
        bDate
        dDate
        vornameJ
        nachnameJ
        aStateJ
        bDateJ
        dDateJ
        aIP
        hsURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
