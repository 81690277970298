import React, { useEffect, useReducer, useState, useContext }
from "react";

import { Modal, Button, Form, Dropdown, Message, Popup, Dimmer, Loader, Divider}
from "semantic-ui-react";

import {AppCtx, getSchool}
from "./App.js";

import {Auth, Hub }
from "aws-amplify";

const initialAuth = { isAuthenticated: false, no:"", email: "", groups: [], authMode: "AWS_IAM" };

//
// SignUpDlg
//
export function SignUpDlg(props) {
   // eslint-disable-next-line no-multi-str
   const regMsg     = 'Geben Sie bitte Ihre Schulnummer und ein Kennwort an und wählen Sie "Registrieren". Sie erhalten dann per \
                      eMail an <Schulnummer>@schule.nrw.de einen Code.\
                      Nach Erhalt der eMail mit dem Code geben Sie diesen ein und wählen "Mit Code bestätigen".';
  // eslint-disable-next-line no-multi-str
  const newCodeMsg = 'Ein neuer Registrierungscode wurde angefordert und per eMail an <Schulnummer>@schule.nrw.de geschickt.\
                      Nach Erhalt der eMail geben Sie diesen in das Feld "Registrierungscode" ein und wählen "Mit Code bestätigen".';
  // eslint-disable-next-line no-multi-str
  const doCodeMsg  = 'Ein Registrierungscode wurde angefordert und per eMail an <Schulnummer>@schule.nrw.de geschickt.\
                      Nach Erhalt der eMail geben Sie diesen in das Feld "Registrierungscode" ein und wählen "Mit Code bestätigen".';

  const doneMsg    = "Registrierung wurde erfolgreich bestätigt. Melden Sie sich für die Bestellung bitte an.";

  const b1Msgs  = [ "Registrieren"       , "Code erneut anfordern"];
  const b2Msgs  = [ "Mit Code bestätigen"];

  const [state, dispatch] = useReducer(reducer, {         isOpen:false,
                                                       authError:false, stepMsg: regMsg,
                                                  requestRunning:false,
                                                           b1Act: 0, b1State:false,
                                                           b2Act: 0, b2State:false,
                                                        username:"", password: "", code: ""
                                                });

  function reducer(state, action) {
    console.log("signUpDlg:reducer:",action.type);

    switch (action.type) {
      case "busy":
        return { ...state, requestRunning: action.isBusy};
      case "show":
        return { ...state, isOpen: action.dlgOpen };
      case "reset":
        return { ...state,authError: false,
                            stepMsg: regMsg,
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy),
                           username: "", password: "", code: ""
               };
      case "newCodeDone":
        return { ...state,authError: false,
                            stepMsg: newCodeMsg,
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
                };
      case "regDone":
        return { ...state,authError: false,
                            stepMsg: doCodeMsg,
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
                };
      case "confirmDone":
        return { ...state,authError: false,  // ToDo: Close Dlg after Timer
                            stepMsg: doneMsg,
                            b1State: false,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorBadSchoolNo":
        return { ...state,authError: true, authMsg: "Ungültige Schulnummer",
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorPasswordPolicy":
        return { ...state,authError: true, authMsg: "Das Kennwort entspricht nicht den Richtlinien",
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "errorRegExists":
        return { ...state,authError: true, authMsg: "Für diese Schulnummer existiert bereits eine Registrierung. Geben Sie bitte die den Code aus der Registrierungsmail ein.",
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "errorCodeMismatch":
        return { ...state,authError: true, authMsg: "Ungültiger oder abgelaufener Code",
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorCodeExpired":
        return { ...state,authError: true, authMsg: "Ungültiger oder abgelaufener Code",
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorRegConfirmed":
//        return { ...state,authError: true, authMsg: "Die Registrierung dieser Schulnummer ist bereits bestätigt. Fordern Sie bei Bedarf ein neues Kennwort über das entsprechende Menü an.",
        return { ...state,authError: true, authMsg: "Die Registrierung dieser Schulnummer ist bereits bestätigt.",
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "editSchool":
        return { ...state, [action.field]: action.value,
                         authError: false,
                             b1Act: 0,
                           b1State: action.value.length === 6 && state.password.length > 7,
                             b2Act: 0,
                           b2State: action.value.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "editPassword":
        return { ...state, [action.field]: action.value,
                         authError: false,
                             b1Act: 0,
                           b1State: state.username.length === 6 && action.value.length > 7,
                             b2Act: 0,
                           b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "editCode":
        return { ...state, [action.field]: action.value,
                         authError: false,
                             b1Act: 1,
                           b1State: state.username.length === 6,
                             b2Act: 0,
                           b2State: state.username.length === 6 && action.value.length === 6,
                     requestRunning: (action.isBusy)
               };
      default:
        console.error("reducer: action.type[", action.type, "] state[", state, "]");
        return { ...state, step: 1 };
    }
  }
  const signUp = async () => {
    const input = {
      username: state.username,
      password: state.password,
      attributes: { email: ""},
    };
    const schoolData = await getSchool(state.username);

    if (schoolData) {
      input.attributes.email = schoolData.mail;
    }

    try {
      dispatch({type:"busy",isBusy:true});
      const userData = await Auth.signUp(input);

      console.log("signUp:", userData);
      dispatch({ type: "regDone" });
    } catch (error) {
      switch (error.code) {
        case "UsernameExistsException":
          console.error("[ERROR - signUp UsernameExistsException]", error);
          dispatch({ type: "errorRegExists" });
          break;
        case "InvalidPasswordException":
          console.error("[ERROR - signUp InvalidPasswordException]", error);
          dispatch({ type: "errorPasswordPolicy"});
          break;
        case "UserLambdaValidationException":
          console.error("[ERROR - signUp UserLambdaValidationException]", error);
          dispatch({ type: "errorBadSchoolNo"});
          break;
        default:
          console.error("[ERROR - signUp]", error);
      }
    }
  };
  const confirmSignUp = async () => {
    console.log(state);
    try {
      dispatch({type:"busy",isBusy:true});
      const userRegData = await Auth.confirmSignUp(state.username, state.code);

      console.log("confirmSignUp:", userRegData);
      dispatch({ type: "confirmDone" });

      const userSigInData = await Auth.signIn({
        username: state.username,
        password: state.password,
      });
      console.log("signIn:", userSigInData);

    } catch (error) {
      switch (error.code) {
        case "CodeMismatchException":
          console.error("[ERROR - confirmSignUp] CodeMismatchException", error);
          dispatch({type: "errorCodeMismatch"});
          break;
        case "ExpiredCodeException":
          console.error("[ERROR - confirmSignUp] ExpiredCodeException", error);
          dispatch({type: "errorCodeExpired"});
          break;
        case "NotAuthorizedException":
          console.error("[ERROR - confirmSignUp] CodeMismatchException", error);
          dispatch({type: "errorRegConfirmed"});
          break;
        case "UserNotConfirmedException":
          console.error("[ERROR - signIn]", error);
          break;
        case "UserNotFoundException":
          console.error("[ERROR - signIn]", error);
          break;
        default:
          console.error("[ERROR - confirmSignUp]", error);
      }
    }
  };
  const resendSignUp = async () => {
    try {
      dispatch({type:"busy",isBusy:true});
      const userData = await Auth.resendSignUp(state.username);

      console.log("resendSignUp:", userData);
      dispatch({ type: "newCodeDone" });
    } catch (error) {
      switch (error.code) {
        case "InvalidParameterException":
          console.error("[ERROR - confirmSignUp] CodeMismatchException", error);
          dispatch({type: "errorRegConfirmed"});
          break;
        default:
          console.error("[ERROR - confirmSignUp]", error);
      }
    }
  };
  return (
    <React.Fragment>
      <Modal
           open={state.isOpen}
        trigger={<Dropdown.Item text="Registrieren" icon="signup" onClick={() => dispatch({ type: "show", dlgOpen: true })} />}
        onMount={() => {dispatch({ type: "reset" });}}
         dimmer="inverted"
           size="small"
      >
      <Modal.Header>Schule für LOGINEO NRW-Messenger registrieren</Modal.Header>
      <Dimmer.Dimmable as={Modal.Content} dimmed={state.requestRunning}>
        <Dimmer active={state.requestRunning} inverted>
          <Loader disabled={!state.requestRunning} active={state.requestRunning}>Operation wird durchgeführt</Loader>
        </Dimmer>
        <Modal.Content>
          <Form error={state.authError}>
            <Popup
              content="Schulnummer Ihrer Schule"
              trigger={
                <Form.Input
                  label="Schulnummer"
                  name="username"
                  placeholder="Schulnummer"
                  width={4}
                  value={state.username}
                  onChange={(e) => {
                    dispatch({
                      type: "editSchool",
                      field: e.target.name,
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            <Popup
              content="Das Passwort muss mindestens 8 Zeichen lang sein, 1 Sonderzeichen, 1 Groß- und 1 Kleinbuchstaben, sowie eine Ziffer enthalten"
              trigger={
                <Form.Input
                  label="Passwort"
                  name="password"
                  placeholder="Passwort"
                  width={6}
                  value={state.password}
                  onChange={(e) => {
                    dispatch({
                      type: "editPassword",
                      field: e.target.name,
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            <Popup
              content="6 stelliger Registrierungscode aus der eMail an <Schulnummer>@schule.nrw.de"
              trigger={
                <Form.Input
                  label="Registrierungscode"
                  name="code"
                  placeholder="000000"
                  width={3}
                  value={state.code}
                  onChange={(e) => {
                    dispatch({
                      type: "editCode",
                      field: e.target.name,
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            <Divider/>
            <Button
              type="button"
              positive
              active
              disabled={!state.b1State}
              onClick={(e) => {
                e.preventDefault();
                state.b1Act === 0 ? signUp() : resendSignUp();
              }}
            >
              {b1Msgs[state.b1Act]}
            </Button>
            <Button
              type="button"
              positive
              active
              disabled={!state.b2State}
              onClick={(e) => {
                e.preventDefault();
                confirmSignUp();
              }}
            >
              {b2Msgs[state.b2Act]}
            </Button>
            <Button
              negative
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: "show", dlgOpen: false });
              }}
            >
              Schliessen
            </Button>

            <Message info content={state.stepMsg} />
            <Message error negative content={state.authMsg} />
          </Form>
        </Modal.Content>
        </Dimmer.Dimmable>
      </Modal>
    </React.Fragment>
  );
}
//
// SignInDlg
//
export function SignInDlg(props) {
  const [state, setState] = useState({ isOpen: false, okEn: false, authError: false, requestRunning:false, authMsg: "", username: "", password: "" });
  const aCtx = useContext(AppCtx);

  const signIn = async () => {
    try {
      setState({ ...state, requestRunning:true, authError: false });
      // eslint-disable-next-line no-unused-vars
      const userData = await Auth.signIn({
        username: state.username,
        password: state.password,
      });
      setState({ ...state, isOpen: false, requestRunning:false, authError: false });

      const schoolData = await getSchool(state.username);
      aCtx.dispatch({type:"auth", school:schoolData});

    } catch (error) {
      switch (error.code) {
        case "UserNotConfirmedException":
          setState({ ...state, authError: true, requestRunning:false, authMsg: "Bitte bestätigen Sie zunächst Ihre Registrierung über den Code aus der Registrierungsmail" });
          break;
        case "UserNotFoundException":
          setState({ ...state, authError: true, requestRunning:false, authMsg: "Schule ist noch nicht registriert oder ungültige Schulnummer" });
          break;
        case "NotAuthorizedException":
          setState({ ...state, authError: true, requestRunning:false, authMsg: "Falsches Passwort" });
          break;
        default:
          setState({ ...state, authError: true, requestRunning:false, authMsg: error.message});
          console.error("[ERROR - signIn]", error);
      }
    }
  };

  function chkState(newState) {
    var stateOk = true;

    stateOk &= newState.username.length === 6;
    stateOk &= newState.password.length > 7;

    setState({ ...newState, okEn: stateOk, authError: false });
  }
  return (
    <React.Fragment>
      <Modal open={state.isOpen} trigger={<Dropdown.Item text="Anmelden" icon="sign in" onClick={() => setState({ ...state, isOpen: true })} />} dimmer="inverted" size="mini">
        <Modal.Header>Anmelden</Modal.Header>
      <Dimmer.Dimmable as={Modal.Content} dimmed={state.requestRunning}>
        <Dimmer active={state.requestRunning} inverted>
         <Loader disabled={!state.requestRunning} active={state.requestRunning}>Operation wird durchgeführt</Loader>
        </Dimmer>
        <Modal.Content>
          <Form error={state.authError}>
            <Popup
              content="Schulnummer"
              trigger={
                <Form.Input
                  label="Schulnummer"
                  name="username"
                  placeholder="Schulnummer"
                  width={6}
                  value={state.username}
                  onChange={(e) => {
                    chkState({ ...state, [e.target.name]: e.target.value });
                  }}
                />
              }
            />
            <Popup
              content="Passwort"
              trigger={
                <Form.Input
                  label="Passwort"
                   name="password"
                   type="password"
            placeholder="Passwort"
                  width={8}
                  value={state.password}
                  onChange={(e) => {
                    chkState({ ...state, [e.target.name]: e.target.value });
                  }}
                />
              }
            />
            <Divider/>
            <Button
              type="button"
              positive
              active
              disabled={!state.okEn}
              onClick={(e) => {
                e.preventDefault();
                signIn();

              }}
            >
              Anmelden
            </Button>
            <Button
              negative
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, isOpen: false });
              }}
            >
              Abbrechen
            </Button>
            <Message error negative content={state.authMsg} />
          </Form>
        </Modal.Content>
        </Dimmer.Dimmable>
      </Modal>
    </React.Fragment>
  );
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// ResetPasswordDlg
//
export function ResetPasswordDlg(props) {
  // eslint-disable-next-line no-multi-str
  const regMsg     = 'Geben Sie bitte Ihre Schulnummer und ein Kennwort an und wählen Sie "Zurücksetzen". Sie erhalten dann per eMail an \
                      <Schulnummer>@schule.nrw.de einen Rücksetzungscode.\
                      Nach Erhalt der eMail mit dem Code geben Sie diesen ein und wählen "Mit Code bestätigen".';
  // eslint-disable-next-line no-multi-str
  const newCodeMsg = 'Ein neuer Rücksetzungscode wurde angefordert und per eMail an <Schulnummer>@schule.nrw.de geschickt.\
                      Nach Erhalt der eMail geben Sie diesen in das Feld "Rücksetzungscode" ein und wählen "Mit Code bestätigen".';
  // eslint-disable-next-line no-multi-str
  const doCodeMsg  = 'Ein Rücksetzungscode wurde angefordert und per eMail an <Schulnummer>@schule.nrw.de geschickt.\
                      Nach Erhalt der eMail geben Sie diesen in das Feld "Rücksetzungscode" ein und wählen "Mit Code bestätigen".';

  const doneMsg    = "Kennwort wurde erfolgreich zurückgesetzt.";

  const b1Msgs  = [ "Zurücksetzen", "Code erneut anfordern"];
  const b2Msgs  = [ "Mit Code bestätigen"];

  const [state, dispatch] = useReducer(reducer, {         isOpen:false,
                                                       authError:false, stepMsg: regMsg,
                                                  requestRunning:false,
                                                           b1Act: 0, b1State:false,
                                                           b2Act: 0, b2State:false,
                                                        username:"", password: "", code: ""
                                                });

  function reducer(state, action) {
    console.log("ResetPasswordDlg:reducer:",action.type);

    switch (action.type) {
      case "busy":
        return { ...state, requestRunning: action.isBusy};
      case "show":
        return { ...state, isOpen: action.dlgOpen };
      case "reset":
        return { ...state,authError: false,
                            stepMsg: regMsg,
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy),
                           username: "", password: "", code: ""
               };
      case "newCodeDone":
        return { ...state,authError: false,
                            stepMsg: newCodeMsg,
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
                };
      case "resetDone":
        return { ...state,authError: false,
                            stepMsg: doCodeMsg,
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
                };
      case "confirmDone":
        return { ...state,authError: false,  // ToDo: Close Dlg after Timer
                            stepMsg: doneMsg,
                            b1State: false,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorRegNotConfirmed":
        return { ...state,authError: true, authMsg: "Für diese Schulnummer existiert keine bestätigte Registrierung. Schliessen Sie bitte die Registrierung mit den Code aus der Registrierungsmail über den Menüpunkt Registrieren ab.",
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                      requestRunning: (action.isBusy)
                       };
      case "errorBadSchoolNo":
        return { ...state,authError: true, authMsg: "Ungültige Schulnummer",
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorPasswordPolicy":
        return { ...state,authError: true, authMsg: "Das Kennwort entspricht nicht den Richtlinien",
                              b1Act: 0,
                            b1State: false,
                              b2Act: 0,
                            b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "errorCodeMismatch":
        return { ...state,authError: true, authMsg: "Ungültiger oder abgelaufener Code",
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "errorCodeExpired":
        return { ...state,authError: true, authMsg: "Ungültiger oder abgelaufener Code",
                              b1Act: 1,
                            b1State: state.username.length === 6,
                              b2Act: 0,
                            b2State: false,
                     requestRunning: (action.isBusy)
               };
      case "editSchool":
        return { ...state, [action.field]: action.value,
                         authError: false,
                             b1Act: 0,
                           b1State: action.value.length === 6 && state.password.length > 7,
                             b2Act: 0,
                           b2State: action.value.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "editPassword":
        return { ...state, [action.field]: action.value,
                         authError: false,
                             b1Act: 0,
                           b1State: state.username.length === 6 && action.value.length > 7,
                             b2Act: 0,
                           b2State: state.username.length === 6 && state.code.length === 6,
                     requestRunning: (action.isBusy)
               };
      case "editCode":
        return { ...state, [action.field]: action.value,
                         authError: false,
                             b1Act: 1,
                           b1State: state.username.length === 6,
                             b2Act: 0,
                           b2State: state.username.length === 6 && action.value.length === 6,
                     requestRunning: (action.isBusy)
               };
      default:
        console.error("reducer: action.type[", action.type, "] state[", state, "]");
        return { ...state, step: 1 };
    }
  }
  const resetPwd = async () => {
    const input = {
      username: state.username,
      password: state.password,
      attributes: { email: "" },
    };
    const schoolData = await getSchool(state.username);

    if (schoolData) {
      input.attributes.email = schoolData.mail;
    }

    try {
      dispatch({type:"busy",isBusy:true});
      const userData = await Auth.forgotPassword(state.username);

      console.log("forgotPassword:", userData);
      dispatch({ type: "resetDone" });
    } catch (error) {
      switch (error.code) {
        case "UserNotFoundException":
          console.error("[ERROR - resetPwd UserNotFoundException]", error);
          dispatch({ type: "errorBadSchoolNo"});
          break;
        case "InvalidPasswordException":
          console.error("[ERROR - resetPwd InvalidPasswordException]", error);
          dispatch({ type: "errorPasswordPolicy"});
          break;
        case "UserNotConfirmedException":
          console.error("[ERROR - resetPwd]", error);
          dispatch({ type: "errorRegNotConfirmed"});
          break;
        case "UserLambdaValidationException":
          console.error("[ERROR - resetPwd UserLambdaValidationException]", error);
          dispatch({ type: "errorBadSchoolNo"});
          break;
        default:
          console.error("[ERROR - resetPwd]", error);
      }
    }
  };
  const confirmReset = async () => {
    console.log(state);
    try {
      dispatch({type:"busy",isBusy:true});
      const userRegData = await Auth.forgotPasswordSubmit(state.username, state.code, state.password)

      console.log("confirmReset:", userRegData);
      dispatch({ type: "confirmDone" });

      const userSigInData = await Auth.signIn({
        username: state.username,
        password: state.password,
      });
      console.log("confirmReset:", userSigInData);

    } catch (error) {
      switch (error.code) {
        case "CodeMismatchException":
          console.error("[ERROR - confirmReset] CodeMismatchException", error);
          dispatch({type: "errorCodeMismatch"});
          break;
        case "ExpiredCodeException":
          console.error("[ERROR - confirmReset] ExpiredCodeException", error);
          dispatch({type: "errorCodeExpired"});
          break;
        case "NotAuthorizedException":
          console.error("[ERROR - confirmReset] CodeMismatchException", error);
          dispatch({type: "errorRegConfirmed"});
          break;
        case "UserNotConfirmedException":
          console.error("[ERROR - confirmReset]", error);
          dispatch({ type: "errorRegNotConfirmed"});
          break;
        case "UserNotFoundException":
          console.error("[ERROR - confirmReset]", error);
          dispatch({ type: "errorBadSchoolNo"});
          break;
        default:
          console.error("[ERROR - confirmReset]", error);
      }
    }
  };
  return (
    <React.Fragment>
      <Modal
           open={state.isOpen}
        trigger={<Dropdown.Item text="Kennwort zürucksetzen" icon="key" onClick={() => dispatch({ type: "show", dlgOpen: true })} />}
        onMount={() => {dispatch({ type: "reset" });}}
         dimmer="inverted"
           size="small"
      >
      <Modal.Header>Kennwort für LOGINEO NRW-Messenger Registrierungsseite zurücksetzen</Modal.Header>
      <Dimmer.Dimmable as={Modal.Content} dimmed={state.requestRunning}>
        <Dimmer active={state.requestRunning} inverted>
         <Loader disabled={!state.requestRunning} active={state.requestRunning}>Operation wird durchgeführt</Loader>
        </Dimmer>
        <Modal.Content>
          <Form error={state.authError}>
            <Popup
              content="Schulnummer Ihrer Schule"
              trigger={
                <Form.Input
                  label="Schulnummer"
                  name="username"
                  placeholder="Schulnummer"
                  width={4}
                  value={state.username}
                  onChange={(e) => {
                    dispatch({
                      type: "editSchool",
                      field: e.target.name,
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            <Popup
              content="Das Passwort muss mindestens 8 Zeichen lang sein, 1 Sonderzeichen, 1 Groß- und 1 Kleinbuchstaben, sowie eine Ziffer enthalten"
              trigger={
                <Form.Input
                  label="Passwort"
                  name="password"
                  type="password"
                  placeholder="Passwort"
                  width={6}
                  value={state.password}
                  onChange={(e) => {
                    dispatch({
                      type: "editPassword",
                      field: e.target.name,
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            <Popup
              content="6 stelliger Rücksetzcode aus der eMail an <Schulnummer>@schule.nrw.de"
              trigger={
                <Form.Input
                  label="Rücksetzcode"
                  name="code"
                  placeholder="000000"
                  width={3}
                  value={state.code}
                  onChange={(e) => {
                    dispatch({
                      type: "editCode",
                      field: e.target.name,
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            <Divider/>
            <Button
              type="button"
              positive
              active
              disabled={!state.b1State}
              onClick={(e) => {
                e.preventDefault();
                resetPwd();
              }}
            >
              {b1Msgs[state.b1Act]} 
            </Button>
            <Button
              type="button"
              positive
              active
              disabled={!state.b2State}
              onClick={(e) => {
                e.preventDefault();
                confirmReset();
              }}
            >
              {b2Msgs[state.b2Act]}
            </Button>
            <Button
              negative
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: "show", dlgOpen: false });
              }}
            >
              Schliessen
            </Button>

            <Message info content={state.stepMsg} />
            <Message error negative content={state.authMsg} />
          </Form>
        </Modal.Content>
        </Dimmer.Dimmable>
      </Modal>
    </React.Fragment>
  );
}
function useAmplifyAuth() {
  const [auth, dispatch] = useReducer(reducer, initialAuth);

  function reducer(auth, action) {
    console.log("doAuth::reducer:", action.type);
    switch (action.type) {
      case "reset":
        return initialAuth;
      case "signIn":
        return { isAuthenticated: true, no:action.no, email: action.email, groups: action.groups, authMode: "AMAZON_COGNITO_USER_POOLS" };
      case "signOut":
        return initialAuth;
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    let isMounted = true;

    const getUser = async () => {
      try
      {
        // eslint-disable-next-line no-unused-vars
        const userData = await Auth.currentAuthenticatedUser();
        console.log("doAuth::getUser, currentAuthenticatedUser found, dispatch signIn");

        dispatch({ type: "signIn", no:userData.username, email: userData.attributes.email, groups: userData.signInUserSession.accessToken.payload["cognito:groups"] });
      }
      catch (error)
      {
        // eslint-disable-next-line no-unused-vars
        const userData = await Auth.currentCredentials();
        console.log("doAuth::getUser currentAuthenticatedUser not found, dispatch reset");
        dispatch({ type: "reset" });
      }
    };

    const HubListener = () => {
      Hub.listen("auth", (data) => {
        const { payload } = data;
        if (isMounted) {
          console.log("HubListener::Event mounted:", payload.event);
          switch (payload.event) {
            case "signIn":
              getUser();
              break;
            case "signIn_failure":
              break;
            case "signUp":
              getUser();
              break;
            case "signUp_failure":
            case "forgotPassword":
            case "forgotPassword_failure":
            case "forgotPasswordSubmit":
            case "forgotPasswordSubmit_failure":
                break;
            case "signOut":
              dispatch({ type: "signOut"});
              getUser();
              break;
            default:
              console.error("HubListener::Event unhandled:", payload.event);
          }
        } else {
          console.log("HubListener::Event not mounted:", payload.event);
        }
      });
    };

    HubListener();
    getUser();

    // on tear down...
    return () => {
      Hub.remove("auth");
      isMounted = false;
    };
  }, [auth.isAuthenticated]);

  const signOut = async () => {
    console.log("signOut");
    try
    {
      await Auth.signOut({ global: true });

    } catch (error)
    {
      console.error("[ERROR - signOut]", error);
    }
  };
  return { auth, signOut };
}

export default useAmplifyAuth;
