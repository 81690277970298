import React
from 'react';

import {Segment, Container, Divider, Header, Grid }
from 'semantic-ui-react';

import  {OrderDlgM}
from './OrderDlgM.js';

import  {OrderDlgJ}
from './OrderDlgJ.js';

export function SchoolPage(props){
    const aStateTxtM = ["Im Portal registriert"                   , "Messenger bestellt", "Messenger in Bereitstellung", "Benachrichtigt"];
    const aStateTxtJ = ["Nach Bereitstellung Messenger bestellbar", "Bestellt"          , "Jitsi in Bereitstellung"    , "Benachrichtigt"];

  function timeToStr(isoDate){
    try{
      const sDate = new Date(isoDate);

      if((isoDate !== null) && (isoDate !== undefined)){
        return sDate.toLocaleString();
      }
    }
    catch (error) {
      console.log("Date error:",error);
    }
    return "";
  }
  return(
    <Segment>
    <Container fluid style={{width: "100%", paddingTop: 80, paddingBottom: 20}}>
        <Header size="medium">
          Beantragung und Verwaltung Logineo-NRW Messenger
        </Header>
      </Container>
      <Grid columns='2'>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Schulnummer:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {props.state.school.no}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Name:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {props.state.school.name}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Strasse:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {props.state.school.street}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            PLZ / Stadt:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {props.state.school.plz + " " + props.state.school.city}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Dienst eMail:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {props.state.school.mail}
          </Grid.Column>
        </Grid.Row>

        <Divider/>

        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Bestellstatus Messenger:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {aStateTxtM[props.state.school.aState]}
          </Grid.Column>
        </Grid.Row>
       <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Bestelldatum Messenger:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {timeToStr(props.state.school.bDate)}
          </Grid.Column>
        </Grid.Row>
       <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Lieferdatum Messenger:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {timeToStr(props.state.school.dDate)}
          </Grid.Column>
        </Grid.Row>
        <Divider/>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Bestellstatus Modul Jitsi:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {aStateTxtJ[props.state.school.aStateJ]}
          </Grid.Column>
        </Grid.Row>
       <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Bestelldatum  Modul Jitsi:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {timeToStr(props.state.school.bDateJ)}
          </Grid.Column>
        </Grid.Row>
       <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
            Lieferdatum  Modul Jitsi:
          </Grid.Column>
          <Grid.Column textAlign='left' width={12}>
            {timeToStr(props.state.school.dDateJ)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right' width={3}>
          </Grid.Column>
          <Grid.Column textAlign='left'  width={12}>
            <Container>
              <Divider/>
              <OrderDlgM />
              <OrderDlgJ />
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
