/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:556e08f6-c56a-4a78-8cd6-7d4f9c719c44",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_P6D9iwMQ1",
    "aws_user_pools_web_client_id": "75j0p4rtmhltq5kc048d08pmac",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://7gtsxzgmjnf7lanothzw5hx6fa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
