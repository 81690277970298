import React, { useEffect, useReducer, useState }
from 'react';

import "./App.css";
import logo from "./lmlogo.png";

import { API }
from "aws-amplify";

import { Header, Container, Menu, Dropdown, Image, Grid, Step}
from "semantic-ui-react";

import { listSchools as listSchoolsQuery,
         getSchool   as getSchoolQuery  }
from './graphql/queries';

import  {SignUpDlg, SignInDlg, ResetPasswordDlg}
from './doAuth.js';

import  {ImpressumDatenschutzPage}
from './ImpressumDatenschutzPage.js';

import  {MusterAVVPage}
from './MusterAVVPage.js';

import  {SchoolPage}
from './SchoolPage.js';

import  {SchoolsPage}
from './SchoolsPage.js';

import  useAmplifyAuth
from './doAuth.js';

const initialState  = { Schools : [], school:{no:""}, page:"home"};

export const AppCtx = React.createContext();
//
// list all schools
//
// eslint-disable-next-line no-unused-vars
async function listSchools(authMode, dispatch) {
  var nSchools  = [];
  var nextToken = null;

  try
  {
    do
    {
      var {data} = await API.graphql({query: listSchoolsQuery, variables: {limit:6000, nextToken:nextToken}, authMode: authMode});

      nextToken  = data.listSchools.nextToken;
      nSchools   = nSchools.concat(data.listSchools.items);
    }
    while (nextToken);
    dispatch({ type: 'list', Schools: nSchools });
  }
  catch(error){
    console.log("listSchoolsQuery::authMode:", authMode ,"] ERROR ", error,", dispatch list []");
    dispatch({ type: 'list', Schools: [] });
  }
}
//
// Get School by no
//
export async function getSchool(no) {
  try
  {
    const { data } = await API.graphql({ query: getSchoolQuery, variables: { no: no, limit: 5}, authMode: "AWS_IAM" });

    if (undefined === data) {
      console.log("getSchoolQuery::data:[undefined]");
    } 
    else {
      console.log("getSchoolQuery::data:[", data.getSchool, "]");
      return data.getSchool;
    }
  }
  catch (error){
    console.error("getSchoolQuery::no:", no, "] ERROR ", error);
  }
}

function App(){
  const { auth, signOut } = useAmplifyAuth();

  const [sortS, setSortS] = useState({sCol: 'no', dir: 1});
  const [state, dispatch] = useReducer(reducer ,initialState);

//  const [list,   setList] = useState(false);

  function reducer(state, action) {
    console.log  ("App::reducer: action.type[", action.type, "] state[", state, "]");
    switch (action.type) {
      case "list": // Not used
        return {...state};
      case "auth":
      case "ordered":
        return {...state, school:action.school   , page:"school"};
      case "school":
        return {...state,                          page:"school"};
      case "deauth":
        return {...state, school:{no:"",aState:0}, page:"home"  };
      case 'sort':  // dispatched by click on table header
        doSrt(state.Schools,action.sortS);
        return {...state};
      case "page":
        return {...state, page:action.page};
      default:
        console.error("App::reducer: action.type[", action.type, "] state[", state, "]");
        return {...state, Schools:[], page:"home"};
    }
  }
//  useEffect(() => { console.log("App:useEffect listschools------------------------------------------");
//                    //listSchools(auth.authMode, dispatch);
//                  },[list]);

  useEffect(() => {
                    if(auth.no === ""){
                      dispatch({type:"deauth"});
                      return;
                    }
                    try   { getSchool(auth.no).then((school) => {dispatch({type:"auth",school:school})});}
                    catch { console.error("getSchool in UseEffect");}
                  },[auth.no]);

  return (
    <AppCtx.Provider value={{state, dispatch}}>
      <div>
        <Menu color="blue" fixed="top" stackable>
          <Container>
            <Menu.Item  onClick={() => {dispatch({type:"page",page:"home"});}}
              style={{ paddingTop: '0em', paddingBottom: '0em' }}>
              <Image src={logo}/>
            </Menu.Item>
            <Menu.Item active={state.page === 'impressumDatenschutz'} onClick={() => {dispatch({type:"page",page:"impressumDatenschutz"});}}>
              Impressum und Datenschutz
            </Menu.Item>
            <Menu.Item active={state.page === 'musteravv'           } onClick={() => {dispatch({type:"page",page:"musteravv"});}}>
              Muster Vereinbarung zur Auftragsverarbeitung
            </Menu.Item>
            <Menu.Menu widths="1" position="right">
              <UserMenu auth={auth}/>
           </Menu.Menu>
           </Container>
         </Menu>
         <Container  style={{ marginTop: '7em' }}>
           <ActivePage school={state.school}/>
         </Container>
      </div>
    </AppCtx.Provider>
  );

  function doSrt(items,sOpts){
    items.sort((a,b) => sOpts.dir * ((a[sOpts.sCol] > b[sOpts.sCol]) -
                                     (b[sOpts.sCol] > a[sOpts.sCol])  ));
  }
  function UserMenu(props) {
//            <Dropdown.Item text="Einstellungen" icon="user"                       />

    console.log("Usermenu:" , props);
    if (props.auth.isAuthenticated){
      return (
        <Dropdown item simple trigger={(<span> {props.auth.email} &nbsp;&nbsp; </span>)} icon="user">
          <Dropdown.Menu>
            <Dropdown.Item text="Abmelden"      icon="sign out"   onClick={signOut} />
            <Dropdown.Item text="Meine Schule"  icon="university" onClick={() => {dispatch({type:"page",page:"school"});}}/>
         </Dropdown.Menu>
        </Dropdown>
      );
    }
    else {
      return (
        <Dropdown item  simple trigger={(<span> Nicht angemeldet &nbsp;&nbsp; </span>)} icon="user">
          <Dropdown.Menu>
            <SignInDlg />
            <SignUpDlg />
            <ResetPasswordDlg />
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }
// <Dropdown.Item text="Passwort vergessen" icon="key"/>

  function ActivePage(props){
    switch(state.page){
      case "school":
        return(<SchoolPage state={state}/>);
      case "schools":
        return(<SchoolsPage sortS={sortS} setSortS={setSortS} Schools={state.Schools}/>);
      case "impressumDatenschutz":
        return(<ImpressumDatenschutzPage/>);
      case "musteravv":
        return(<MusterAVVPage/>);
      case "home":
      default:
        return (<IntroPage/>);
    }
  }
//  <Icon name='edit outline' />
//  <Icon name='tasks' />
//  <Icon name='shipping fast' />
//  <Icon name='wrench' />
//  <Icon name='thumbs up outline' />

  function IntroPage(){
    return (
      <Container>
        <Grid stackable columns='2'>
            <Grid.Column floated='left' width={6}>
              <Container style={{paddingTop: 90}}>
                <Step.Group ordered vertical>
                  <Step>
                    <Step.Content>
                      <Step.Title>Registrierung</Step.Title>
                      <Step.Description>
                        Registrierung über Schulnummer
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step>
                    <Step.Content>
                      <Step.Title>Anforderung</Step.Title>
                      <Step.Description>
                        Bestätigung Voraussetzungen
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step>
                    <Step.Content>
                      <Step.Title>Bereitstellung</Step.Title>
                      <Step.Description>
                        AVV, Messenger und Login
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step>
                    <Step.Content>
                      <Step.Title>Einrichtung</Step.Title>
                      <Step.Description>
                        Einrichtung mit Schild Daten
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step>
                    <Step.Content>
                      <Step.Title>Start</Step.Title>
                      <Step.Description>
                        Nutzung LOGINEO-NRW Messenger
                      </Step.Description>
                    </Step.Content>
                  </Step>
                  <Step>
                    <Step.Content>
                      <Step.Title>Erweiterung Video</Step.Title>
                      <Step.Description>
                        Erweiterung LOGINEO-NRW Messenger auf Jitsi
                      </Step.Description>
                    </Step.Content>
                  </Step>
                </Step.Group>
              </Container>
            </Grid.Column>
            <Grid.Column textAlign='left' floated='left' width={10}>
              <Container style={{paddingTop: 90}}>
                <Header size="medium">
                  LOGINEO-NRW Messenger <br/><br/>
                  für alle öffentlichen Schulen und anerkannten Ersatzschulen in NRW
                </Header>
                <Header size="small">
                  Vielen Dank für Ihr Interesse am LOGINEO NRW Messenger.<br/><br/>

                  Der LOGINEO NRW Messenger steht allen öffentlichen Schulen und privaten Ersatzschulen in Nordrhein-Westfalen kostenlos zur Verfügung.<br/><br/>

                  Durch das weitgehend automatisierte Antragsverfahren können die Schulen den Messenger in der Regel sehr schnell erhalten. Während des gesamten Antragsprozesses informieren wir Sie auf dieser Seite über den aktuellen Stand Ihres Antrags.<br/>
                  Bei Fragen oder Problemen zum Anmeldeprozess können Sie sich per E-Mail wenden an<br/>
                  anmeldesupport@logineonrw-messenger.de<br/><br/>

                  Eine Dokumentation zum Registrierungsprozess können Sie hier herunterladen: <a href="DokuMessengerReg1.04.pdf">Dokumentation Registrierungsprozess</a>
                </Header>
              </Container>
            </Grid.Column>
        </Grid>
      </Container>
    );
  }
}
export default App;
